import { loadAuthToken } from "../../../auth/utils/utils";
import GetAppIcon from "@mui/icons-material/GetApp";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";

const RevisionExportButton = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleExport = () => {
    setLoading(true);
    const authToken = loadAuthToken();
    axios
      .get(process.env.REACT_APP_ENDPOINT_SERVER + "/export/revisions", {
        responseType: "blob",
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then((response) => {
        const href = URL.createObjectURL(response.data);

        const dt = new Date();

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute(
          "download",
          "Fixnote - Report revizí z " + dt.toLocaleDateString() + ".xlsx"
        ); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <LoadingButton
        loading={loading}
        variant="outlined"
        startIcon={<GetAppIcon />}
        // sx={{
        //   mt: 2
        // }}
        // fullWidth
        onClick={() => {
          handleExport();
        }}
      >
        Exportovat
      </LoadingButton>
    </>
  );
};

export default RevisionExportButton;
